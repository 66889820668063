import React from 'react'
import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '~/components/Layout'
import MakerLinksPage from '~/components/Maker/Links/Page'
import Metadata from '~/components/Metadata'

const MakerLinksTemplate = ({ data }) => {
  const { maker } = data
  const { image, name, wineryName } = maker

  return (
    <Layout includeHeaderFooter={false}>
      <Metadata
        title={`${name} Links`}
        description={`Our favorite links for ${name} of ${wineryName}.`}
        image={getSrc(image)}
        noindex={true}
      />
      <MakerLinksPage maker={maker} />
    </Layout>
  )
}

export const query = graphql`
  query MakerLinksQuery($slug: String) {
    maker: contentfulMaker(slug: { eq: $slug }) {
      ...MakerLinksFragment
    }
  }
`

export default MakerLinksTemplate
